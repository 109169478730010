import {
  userRepository,
  useUserStore,
  changeUserSettingsUseCase,
  type UserSelected,
} from '~/domains/user';
import { useSnackbarNotificationService } from '~/domains/core';
import { useI18n } from '~/infrastructure';

const useChangeUserSettingsAdapter = (): {
  changeUserSettings: (
    settings: UserSelected,
  ) => Promise<UserSelected | undefined>;
} => {
  const { setUserSelected } = useUserStore();
  const { t } = useI18n();
  const { notifyError } = useSnackbarNotificationService();

  const changeUserSettings = async (
    settings: UserSelected,
  ): Promise<UserSelected | undefined> => {
    return await changeUserSettingsUseCase(settings, {
      notifier: {
        notifyError: () => {
          notifyError(t('common.error.somethingWentWrong'));
        },
      },
      userRepository,
      storage: {
        setUserSelected,
      },
    });
  };

  return {
    changeUserSettings,
  };
};

export default useChangeUserSettingsAdapter;
