import { defineStore } from 'pinia';
import { readonly, ref, computed } from 'vue';
import {
  type UserSelected,
  type UserInfo,
  type UserPreferences,
  getUserInfoViewModel,
} from '~/domains/user';
import { useOnChangeCurrency } from '~/domains/user/index';

const useUserStore = defineStore('user', () => {
  const user = ref<UserInfo | null>(null);
  const preferences = ref<UserPreferences | null>(null);
  const { fetchUserInfo } = getUserInfoViewModel();

  const showDropship = computed(() => user.value?.canUseDropship);
  const showDep = computed(() => user.value?.functions?.includes('DEP'));
  const selectedCompany = computed(
    () =>
      user.value?.companies?.find(
        (company) => company.id === user.value?.selected?.customerId,
      ) ?? null,
  );

  const setUser = (newUser: UserInfo | null): void => {
    user.value = newUser;
  };

  const setUserSelected = (data: UserSelected): void => {
    if (user.value === null) return;
    user.value.selected = data;
  };

  const showPricesWithVat = computed(
    () => user.value?.selected.showPricesWithVat ?? false,
  );

  const getUserCredit = async (): Promise<void> => {
    if (user.value === null) return;

    await fetchUserInfo();
  };

  const setUserPreferences = (data: UserPreferences): void => {
    preferences.value = data;
  };

  useOnChangeCurrency(() => {
    getUserCredit();
  });

  return {
    setUser,
    setUserSelected,
    setUserPreferences,
    user: readonly(user),
    preferences: readonly(preferences),
    showPricesWithVat,
    showDep,
    showDropship,
    selectedCompany,
  };
});

export default useUserStore;
