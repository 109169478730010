import dayjs, { type ManipulateType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { computed, type ComputedRef } from 'vue';
import { LOCALE_FORMAT_MAP, DEFAULT_DATE_FORMAT_PATTERN } from '../constants.d';
export interface UseDateFormatterReturn {
  dayjs: any;
  currentYear: ComputedRef<number>;
  getDate: (date?: Date | string) => Date | undefined;
  getDateFromLocalDateString: (date: string) => Date;
  getLocalDateString: (date?: Date) => string | undefined;
  getSubtractDate: (date: Date, value: number, unit: ManipulateType) => Date;
  handleDateOutput: (date?: Date) => string;
  handleDateOutputWithTime: (date?: Date) => string;
  isDateInstance: (value: any) => boolean;
  isLocalDateStringValid: (value: any) => boolean;
  isDateValid: (value: Date | string) => boolean;
  toTheStartOfIsoDate: (date: Date | string) => string;
  toTheEndOfIsoDate: (date: Date | string) => string;
  getIsoDateStringWithoutTime: (date: Date | string) => string;
  handleDateFromApi: (date: Date | string) => Date;
  toIso: (date?: Date | string) => string | undefined;
  isBefore: (date1: Date | string, date2: Date | string) => boolean;
}

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const getFormatPatternDependOnLocale = (locale: string): string => {
  return LOCALE_FORMAT_MAP[locale] ?? DEFAULT_DATE_FORMAT_PATTERN;
};

export default function useDateFormatter(): UseDateFormatterReturn {
  const navigatorLanguage = navigator.language;
  const currentDateFormat = getFormatPatternDependOnLocale(navigatorLanguage);

  const currentDateFormatWithTime = computed(
    () => `${currentDateFormat} HH:mm`,
  );
  const currentYear = computed(() => dayjs().year());

  // Get iso (YYYY-MM-DD) string from Date or string
  const getIsoDateStringWithoutTime = (date: Date | string): string => {
    return dayjs(date).format('YYYY-MM-DD');
  };

  // Get date from iso (YYYY-MM-DD) format string
  const getDate = (date?: Date | string): Date | undefined => {
    if (date === undefined) return undefined;

    return dayjs(date).toDate();
  };

  // Get local date without time from Date or string. It needs for correct date. Were problems with timezone.
  const getLocalDateStringWithoutTime = (date: string | Date): string => {
    return dayjs.utc(date).format(currentDateFormat);
  };

  // Handle date from API
  const handleDateFromApi = (date: string | Date): Date => {
    const localDateString = getLocalDateStringWithoutTime(date);

    return dayjs(localDateString, currentDateFormat).toDate();
  };

  // Get date from local date format string, for example, from Belarus - DD.MM.YYYY
  const getDateFromLocalDateString = (date: string): Date => {
    return dayjs(date, currentDateFormat).toDate();
  };

  // Get local date string from Date
  const getLocalDateString = (date?: Date): string | undefined => {
    return dayjs(date).format(currentDateFormat);
  };

  // Get local date string with time from Date
  const getLocalDateStringWithTime = (date?: Date): string | undefined => {
    return dayjs(date).format(currentDateFormatWithTime.value);
  };

  // Date output in places when need string in local format
  const handleDateOutput = (date?: Date): string => {
    if (date === undefined) return '–';

    return getLocalDateString(date) ?? '–';
  };

  // Date output in places when need string in local format with time
  const handleDateOutputWithTime = (date?: Date): string => {
    if (date === undefined) return '–';

    return getLocalDateStringWithTime(date) ?? '–';
  };

  // For deserializer from, format to correct day with 00:00:00 time
  const toTheStartOfIsoDate = (date: Date | string): string => {
    return dayjs(date)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DDTHH:mm:ss[Z]');
  };

  // For deserializer till, format to correct day with 23:59:59 time
  const toTheEndOfIsoDate = (date: Date | string): string => {
    return dayjs(date)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .format('YYYY-MM-DDTHH:mm:ss[Z]');
  };

  // Just convert to iso
  const toIso = (date?: Date | string): string | undefined => {
    return dayjs(date).toISOString();
  };

  // For subtract from date
  const getSubtractDate = (
    date: Date,
    value: number,
    unit?: ManipulateType,
  ): Date => {
    return dayjs(date).subtract(value, unit).toDate();
  };

  const isBefore = (date1: Date | string, date2: Date | string): boolean => {
    return dayjs(date2).isBefore(dayjs(date1));
  };

  // Is it instance of Date object
  const isDateInstance = (value: any): boolean => {
    return value instanceof Date;
  };

  // Is valid date string , it will be true for example for this formats DD.MM.YYYY , DD/MM/YYYY, DD.MM/YYYY, DDюMMюYYYY
  const isLocalDateStringValid = (value: string): boolean => {
    return dayjs(value, currentDateFormat).isValid();
  };

  // Is valid Date
  const isDateValid = (value: Date | string): boolean => {
    return dayjs(value).isValid();
  };

  return {
    dayjs,
    currentYear,
    getLocalDateString,
    getDate,
    getSubtractDate,
    handleDateOutput,
    isDateInstance,
    isLocalDateStringValid,
    getDateFromLocalDateString,
    toTheStartOfIsoDate,
    toTheEndOfIsoDate,
    isDateValid,
    getIsoDateStringWithoutTime,
    handleDateFromApi,
    toIso,
    isBefore,
    handleDateOutputWithTime,
  };
}
