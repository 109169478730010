import { type LoginResponse } from './types.d';

const authSerializer = {
  login(data: LoginResponse) {
    return {
      token: data.token,
      status: data.status,
      message: data.error_message,
      triesLeft: data.tries_left,
    };
  },
};

export default authSerializer;
