import { useSnackbar } from '~/ui';
import { type NotificationService } from '~/domains/core';
import { useI18n } from 'vue-i18n';

export default function useSnackbarNotificationService(): NotificationService {
  const snackbar = useSnackbar();
  const { t } = useI18n();

  const notifyError = (message: string): void => {
    snackbar.add({
      type: 'error',
      text: message !== '' ? message : t('common.error.somethingWentWrong'),
    });
  };
  const notifyWarning = (message: string): void => {
    snackbar.add({
      type: 'warning',
      text: message !== '' ? message : t('common.notification.warning.text'),
    });
  };

  const notifySuccess = (message: string): void => {
    snackbar.add({
      type: 'info',
      text: message,
    });
  };

  return {
    notifySuccess,
    notifyWarning,
    notifyError,
  };
}
