import userRepository from './user.repository';
import useUserStore from '~/domains/user/user.store';
import {
  type ChangePasswordFormData,
  type ChangePasswordStatus,
} from '~/domains/auth/types.d';
import {
  type UserSelected,
  type UserInfo,
  type UserChangeUserInfo,
  type UserPreferences,
} from '~/domains/user';

const userService = {
  async getUserInfo(): Promise<UserInfo> {
    return await userRepository.getUser();
  },

  async changeUserInfo(body: UserChangeUserInfo): Promise<UserInfo | null> {
    const changedUserInfo = await userRepository.changeUserInfo(body);
    return changedUserInfo;
  },

  async changePassword(
    changePasswordFormData: ChangePasswordFormData,
    username: string,
  ): Promise<ChangePasswordStatus> {
    const formData = new FormData();

    formData.append('username', username);
    formData.append('current', changePasswordFormData.current);
    formData.append('password', changePasswordFormData.password);

    const response = await userRepository.changePassword(formData);
    localStorage.removeItem('isNeedToChangePassword');

    return response.status;
  },

  async changeUserSettings(body: UserSelected): Promise<UserSelected | null> {
    const store = useUserStore();
    const response = await userRepository.changeUserSettings(body);

    store.setUserSelected(response);
    return response;
  },

  async getUserPreferences(): Promise<UserPreferences> {
    const store = useUserStore();
    const response = await userRepository.getUserPreferences();

    store.setUserPreferences(response);
    return response;
  },

  async changeUserPreferences(body: UserPreferences): Promise<UserPreferences> {
    const store = useUserStore();
    const response = await userRepository.changeUserPreferences(body);

    store.setUserPreferences(response);
    return response;
  },
};

export default userService;
