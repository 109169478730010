import { type UserRepository, type UserSelected } from '~/domains/user';
import { type NotificationService } from '~/domains/core';

interface Dependencies {
  notifier?: NotificationService;
  userRepository: UserRepository;
  storage?: {
    setUserSelected: (userInfo: UserSelected) => void;
  };
}

const changeUserSettingsUseCase = async (
  settings: UserSelected,
  { notifier, userRepository, storage }: Dependencies,
): Promise<UserSelected | undefined> => {
  try {
    const response = await userRepository.changeUserSettings(settings);

    if (response !== undefined) {
      storage?.setUserSelected(response);
    }
    return response;
  } catch (e) {
    notifier?.notifyError();
  }
};

export default changeUserSettingsUseCase;
